<template>
    <r-e-dialog title="批量备注" class="layer-contract-detail" :visible.sync="dialogVisible" show-footer
                top="10vh" width="1050px" @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="90px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="备注房间" prop="apartmentList">
                    <el-transfer v-model="apartmentList" :props="{key: 'uuid',label: 'name'}" :data="apartmentData"
                                 :titles="['可选房间','已选房间']" :button-texts="['移除','添加']"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="备注" prop="remarks">
                    <el-input type="textarea" v-model="formPublish.remarks" :autosize="{ minRows: 2, maxRows: 4}"
                              clearable style="width: 800px;"/>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import {getApartmentAllListApi,batchRemarks} from "@/api/housing-resources-management";
    import { MessageInfo, MessageError, MessageSuccess} from "@custom/message";
    export default {
        name: "dialog-batch-remarks",
        data(){
            return {
                dialogVisible: false,
                formPublish: {
                    apartmentList: [],
                    remarks: '',
                },
                rules: {
                    apartmentList: [{required: true, message: '请选择备注房间', trigger: 'change'}]
                },
                apartmentList: [],
                apartmentData: [],
                loadingOptions: {
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                }
            };
        },
        props: {},
        methods:{
            async openDialog(data){
                let that = this;
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                let res = await getApartmentAllListApi(data).finally(()=> loading.close());
                const { list } = res;
                that.apartmentData = list;
                that.dialogVisible = true;
            },
            clickSubmit() {
                let that = this;
                that.$refs["formPublish"].validate((valid) => {
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    let {apartmentList,remarks} = that.formPublish;
                    apartmentList = apartmentList.join(";");
                    const data = {apartmentList, remarks};
                    batchRemarks(data).then(res=>{
                        MessageSuccess('修改成功');
                        this.clickCancel();
                    }).finally(()=> loading.close());
                });
            },
            clickCancel(){
                this.$emit('handleSearch',false);
                this.apartmentList = [];
                this.formPublish = {
                    apartmentList: [],
                    remarks: '',
                }
                this.dialogVisible = false;
            },
        },
        watch:{
            apartmentList(value){
                this.formPublish.apartmentList = value;
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-transfer-panel {
        border: 1px solid #EBEEF5;
        border-radius: 4px;
        overflow: hidden;
        background: #FFF;
        display: inline-block;
        vertical-align: middle;
        width: 340px;
        max-height: 100%;
        box-sizing: border-box;
        position: relative;
    }
</style>